@font-face {
  font-family: 'HMSansBold';
  src: url('../fonts/HMSansBold.otf');
}

@font-face {
  font-family: 'HMSansRegular';
  src: url('../fonts/HMSansRegular.otf');
}

@font-face {
  font-family: 'HM Sans';
  src: url('../fonts/HMSansSemiBold.otf');
}

@font-face {
  font-family: 'HMSansSemiBold';
  src: url('../fonts/HMSansSemiBold.otf');
}

@font-face {
  font-family: 'HMSansLight';
  src: url('../fonts/HMSansLight.otf');
}

// If you want to override variables do it here
@import 'variables';

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles

@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

@import 'header';
@import 'sidebar';
// @import 'peakSeason';
// If you want to add custom CSS you can put it here.
@import 'custom';

html body {
  height: 100%;
  font-family: 'HMSansRegular', sans-serif;
  scroll-behavior: smooth;
}
.leftArrow-GAV,
.rightArrow-GAV,
.leftArrowDisabled-GAV,
.rightArrowDisabled-GAV {
  background: url('../images/svgs/sdf-sprite.svg');
}
.sprite-image-url {
  background: url('../images/svgs/sdf-sprite.svg');
}
.logo-image-url {
  background: url('../images/svgs/logo.svg');
}
.cx-odf-online {
  background: url('../images/onlinedataflow/CXView/sprite.svg');
  cursor: default;
}
.control-odf-online {
  background: url('../images/onlinedataflow/ControllingView/control_Sprite.svg');
}
.sprite-consolidated {
  background: url('../images/svgs/consolidateSprite.svg');
}
.flowchart-popover-table {
  .table > :not(caption) > * > * {
    background-color: transparent !important;
  }
  .table {
    --cui-table-bg: transparent !important;
  }
}
.chatbotIcon {
  position: absolute;
  top: 90%;
  left: 90%;
  z-index: 10000000;
  cursor: pointer;
}
.chatModal {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10000001;
}
#pr_id_3 {
  background-color: white !important;
}
.react-flow__attribution {
  display: none !important;
}

.react-flow__handle {
  background: transparent !important;
  border: none !important;
}

.resetbtn {
  border: 0px;
  background: transparent;
  font-family: 'HM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.resetWhiteIcon {
  background-position: -380px -195px;
  width: 16px;
  height: 19px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.resetbtn {
  border: 0px;
  background: transparent;
  font-family: 'HM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.resetWhiteIcon {
  background-position: -380px -195px;
  width: 16px;
  height: 19px;
}
#overlay {
  //background: rgb(255,255,255, 0.3);
  color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.17);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* opacity: 0.1;*/
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid firebrick;
  border-right-color: transparent;
  border-radius: 50%;
}
.resetbtnOnZoom {
  border: 0px;
  font-family: 'HM Sans', sans-serif;
  font-style: normal;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 7px;
  background: rgba(8, 19, 32, 0.8);
  backdrop-filter: blur(2px);
  width: 95px;
  height: 38px;
}
