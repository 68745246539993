// Here you can add other styles

.background {
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  background: radial-gradient(
    111.31% 83.08% at 34.46% 29.17%,
    #435268 0%,
    #1d3551 36.98%,
    #0d2337 66.84%,
    #1d3551 100%
  );
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button,
.btn-login-button:hover,
.btn-login-button:active {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #ffffff;
}
.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}
.header {
  background-color: #0f1e30 !important;
  border: none !important;
}

.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}
.breadcrumb-item::before {
  content: '' !important;
}
.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}
.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 44px;
  color: #ffffff;
  &.hours-data-flow-heading {
    font-family: 'HM Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
  .hours-data-flow-subheading {
    font-family: 'HMSansRegular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.colvaluewidth {
  width: 26.2%;
}
.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}

.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;
  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }
  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}
.guage-alignment {
  padding-inline-start: 5px;
}
.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }
  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }
  .cardPercentage {
    font-size: 13px;
    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;
      &.upIcon {
        background: url(../images/up-icon.png);
      }
      &.downIcon {
        background: url(../images/down-icon.png);
      }
    }
    .percentageText {
      color: #555555;
      &.upIconText {
        color: #579c21;
      }
      &.downIconText {
        color: #e44659;
      }
    }
  }
  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;
    img {
      width: 50px;
    }
  }
}
.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}
.ga-view-wrap,
.accountant-summary-popover {
  input#calendar-chkbox[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin-right: 8px;

    font: inherit;
    color: #fff;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #fff;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input#calendar-chkbox[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #fff;
    /* Windows High Contrast Mode */
    background-color: transparent;
  }

  input#calendar-chkbox[type='checkbox']:checked::before {
    transform: scale(1);
  }

  .react-calendar__navigation__label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__navigation {
    position: relative;
    .react-calendar__navigation__label {
      visibility: hidden;
    }
    .react-calendar__navigation__labelCustom {
      flex-grow: 1;
      position: absolute;
      color: white;
      display: flex;
      left: 25%;
      min-width: 150px;

      &:hover,
      &:focus {
        background-color: transparent !important;
      }
    }
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .ga-view-wrap {
    .react-calendar__navigation__labelCustom {
      justify-content: center;
      left: 14% !important;
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .ga-view-wrap {
    .react-calendar__navigation__labelCustom {
      justify-content: center;
      left: 18% !important;
    }
  }
}
.toast-container .toast {
  display: none;
}

.toast-container .toast:first-child {
  display: block;
}
.closeIcon-calendar-Container {
  top: -4px;
  position: absolute;
  z-index: 1000;
  right: -9px;
  cursor: pointer;
}
.submodalCloseIcon {
  background-position: -12px -279px !important;
  width: 32px;
  height: 32px;
  display: flex;
  float: right;
}
.closeIcon-Container {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 1000;
}
.alert-icon {
  background: url('../images/svgs/sdf-sprite.svg') no-repeat -231px -228px;
  width: 26px;
  height: 26px;
  top: 0%;
}
