.searchIcon {
  border: 2px solid #ffffff;
  border-radius: 50px;
  background-color: transparent !important;
}

.headericon-lg {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.headerhmlogo-lg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}
.headerTitle-lg {
  position: absolute;
  top: 24px;
  line-height: 38px;
  /* identical to box height */
  color: #cc071e;
}
.headersearch {
  padding-right: 10px;
  margin-right: 0;
}
.search-control {
  height: 35px !important;
  border-radius: 30px;
  background-color: transparent;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.4);
  border-left-color: white;
}
.profileAvatar {
  background-color: #fdfdfd;
  color: #000000 !important;
  font-size: 16px;
}
.common-header-username {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
